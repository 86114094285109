import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import './Refund.css'

function Refund() {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <Box className='refund' >
        <Box className='refund_name' > ОБМЕН И ВОЗВРАТ</Box>
            <Box className='first_refund_info' sx={{textAlign: 'justify'}}> 
                Мы надеемся, что вы остались довольны покупкой в CouCou, однако, если товар вам не подошел по разным причинам, Вы можете его вернуть или осуществить обмен в течение 14 календарных дней с момента получения заказа.
            </Box>
            <Box className='refund_info'>
                <Box className='refund_info_1'> 
                    <Box className='refund_info_1_name'>
                        КАКОЙ ТОВАР МОЖНО ОБМЕНЯТЬ ИЛИ ВЕРНУТЬ
                    </Box>
                    <Box className='refund_info_1_text'>
                    Товар должен:
                    <p>- cохранить свои потребительские свойства и иметь безупречный товарный вид.</p>
                    <p>- не иметь следов эксплуатации, носки (подошва не имеет царапин и пятен, верхняя часть обуви не имеет заломов, царапин и складок).</p>
                    <p>- упаковка (в т. ч. производственная слюда) и фабричная фурнитура должны быть сохранены и не повреждены.</p>
                    </Box>
                </Box>
                <Box className='refund_info_2'> 
                    <Box className='refund_info_2_name'>
                        УСЛОВИЯ ОБМЕНА
                    </Box>
                    <Box className='refund_info_2_text'>
                    Для оформления обмена необходимо сначала оформить возврат, а затем оформить новый заказ.

                    </Box>
                </Box>
                <Box className='refund_info_3'> 
                    <Box className='refund_info_3_name'>
                        УСЛОВИЯ ВОЗВРАТА
                    </Box>
                    <Box className='refund_info_3_text'>
                    <p>
                    - возврат осуществляется за счет покупателя (оплата при отправки изделия в пункте CDEK).
                    </p>
                    <p>- возврат денежных средств осуществляется только за товар, без учета транспортных расходов.</p>
                    <p>- возврат наличных средств при оплате товара курьеру в г. СПб осуществляется в соответствии со стандартным порядком возврата.</p>
                    </Box>
                </Box>
                <Box className='refund_info_4'> 
                    <Box className='refund_info_4_name'>
                        ПОРЯДОК ВОЗВРАТА
                    </Box>
                    <Box className='refund_info_4_text'>
                    <p>- заполните заявление на возврат, которое вы получили с товаром.</p>
                    <p>- свяжитесь с менеджером в <a className='link' style={{color: 'inherit'}}  href="https://t.me/CouCou_spb" >Telegram</a> или <a className='link' style={{color: 'inherit'}} href="https://wa.me/79112869339">WhatsApp</a>.</p>
                    <p>- менеджер создаст отправление в CDEK, по которому Вы сможете отправить изделие обратно, и сообщит Вам трек-номер.</p>
                    <p>- аккуратно упакуйте неподошедший товар вместе с заявлением на возврат.</p>
                    <p>- обратитесь с данным трек-номером в любой пункт CDEK. При себе необходимо иметь документ, удостоверяющий личность.</p>
                    <p>- денежные средства будут возвращены на карту, с которой был оплачен заказ, или на реквизиты, которые вы укажете в заявлении на возврат, если ваша карта была заблокирована/утеряна.</p>
                    </Box>
                </Box>
                <Box className='refund_info_5'> 
                    <Box className='refund_info_5_name'>
                        СРОКИ ВОЗВРАТА
                    </Box>
                    <Box className='refund_info_5_text'>
                    <p>- денежные средства будут перечислены в течение 10 дней с момента получения возврата на нашем складе.</p> 
                    <p>- срок поступления денежных средств на Ваш счет зависит от скорости обработки операций Вашим банком и может достигать 30 банковских дней.</p>
                    </Box>
                </Box>
            </Box>
    </Box>
);
}

export default Refund;
